define('webui/pods/components/csv-export-settings-modal/component', ['exports', 'webui/mixins/export-options'], function (exports, _exportOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exportOptions.default, {
    pdfOptions: Ember.inject.service('pdf-options'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),

    ttrCSV: false,
    accountingCSV: false,
    normalReport: false,
    coLobLobActive: false,
    coLocLobValue: null,

    init: function init() {
      this._super();
      this.setupFormData();
    },
    setupFormData: function setupFormData() {
      switch (this.get('type')) {
        case 'quantity':
          this.setupQuantityFormData();
          this.set('normalReport', true);
          break;
        case 'tracking-cut-fill':
          this.setupTrackingCutFillFormData();
          this.set('normalReport', true);
          break;
        case 'true-tonnage':
          this.setupTrueTonnageFormData();
          this.set('normalReport', false);
          break;
        case 'volume-calc':
          this.setupVolumeCalcFormData();
          this.set('normalReport', true);
          break;
        default:
      }

      this.set('coLobLobActive', false);
      this.set('coLocLobValue', null);
    },
    didRender: function didRender() {
      this.setupSelectPickers();
    },
    setupSelectPickers: function setupSelectPickers() {
      $('#export-column-selector').selectpicker();
      $('#export-row-selector').selectpicker();
      $('#export-flight-selector').selectpicker();
      $('#export-surface-selector').selectpicker();
    },
    willDestroyElement: function willDestroyElement() {
      $('#export-column-selector').selectpicker('destroy');
      $('#export-row-selector').selectpicker('destroy');
      $('#export-flight-selector').selectpicker('destroy');
      $('#export-surface-selector').selectpicker('destroy');
      // this.set('pdfOptions.useMinifiedRegionNames', false);
      // this.set('pdfOptions.customHeader', null);
    },
    setupQuantityFormData: function setupQuantityFormData() {
      this.set('flights', this.getFlightOptions());
      this.set('columns', this.getQuantityReportColumns());
    },
    setupTrackingCutFillFormData: function setupTrackingCutFillFormData() {
      this.set('surfaces', this.getBlueprintOptions());
      this.set('flights', this.getFlightOptions());
      this.set('columns', this.getTrackingCutFillReportColumns());
    },
    setupTrueTonnageFormData: function setupTrueTonnageFormData() {
      this.set('columns', this.getTrueTonnageReportColumns());
      this.set('rows', this.getVolumeCalcReportRows());
    },
    setupVolumeCalcFormData: function setupVolumeCalcFormData() {
      this.set('columns', this.getVolumeCalcReportColumns());
      this.set('rows', this.getVolumeCalcReportRows());
    },
    setupAccountingTrueTonnageFormData: function setupAccountingTrueTonnageFormData() {
      this.set('columns', this.getTrueTonnageAccountingReportColumns());
    },
    getDropdownOptions: function getDropdownOptions(selectElementId) {
      return Array.from(document.querySelector(selectElementId).querySelectorAll('option'));
    },
    getUnselectedItems: function getUnselectedItems(selectElementId) {
      return this.getDropdownOptions(selectElementId).filter(function (option) {
        return !option.selected;
      }).map(function (option) {
        return option.value;
      });
    },
    setDefaultCoLocLobHeaders: function setDefaultCoLocLobHeaders() {
      var flight = this.get('store').peekRecord('flight', this.get('session.currentFlight'));
      var coLocLob = flight.get('site.description') ? flight.get('site.description') : null;
      this.set('pdfOptions.coLocLob', coLocLob);
    },


    actions: {
      downloadCSV: function downloadCSV(type) {
        var settings = { columnsToOmit: this.getUnselectedItems('#export-column-selector') };
        try {
          if (type !== 'accountingCSV') {
            settings.rowsToOmit = this.getUnselectedItems('#export-row-selector');
          }

          if (type) {
            settings.reportType = type;
          }

          if (this.get('coLobLobActive')) {
            settings.coLocLob = this.get('coLocLobValue');
          }
        } catch (e) {
          Ember.Logger.error(e);
        }
        if (this.get('flights')) settings.flightsToOmit = this.getUnselectedItems('#export-flight-selector');
        if (this.get('surfaces')) settings.surfacesToOmit = this.getUnselectedItems('#export-surface-selector');

        this.sendAction('downloadCSV', settings);
      },
      toggleDownloadForm: function toggleDownloadForm() {
        this.sendAction('toggleDownloadForm');
        this.set('pdfOptions.useMinifiedRegionNames', false);
        this.set('pdfOptions.customHeader', null);
      },
      toggleUseMinifiedRegionNames: function toggleUseMinifiedRegionNames() {
        this.toggleProperty('pdfOptions.useMinifiedRegionNames');
      },
      toggleCustomHeader: function toggleCustomHeader(type) {
        if (this.get('pdfOptions.customHeader')) {
          this.set('pdfOptions.customHeader', null);
        } else {
          var defaultHeader = this.getDefaultHeader(type);
          this.set('pdfOptions.customHeader', defaultHeader);
        }
      },
      updateCustomHeader: function updateCustomHeader(newHeader) {
        this.set('pdfOptions.customHeader', newHeader.replace(/,/g, ''));
      },
      updateCoLocLobHeaders: function updateCoLocLobHeaders(value) {
        this.set('pdfOptions.coLocLob', value);
      },
      toggleRegularCSV: function toggleRegularCSV() {
        this.set('pdfOptions.useMinifiedRegionNames', false);
        this.set('accountingCSV', false);
        this.toggleProperty('ttrCSV');
        this.set('pdfOptions.customHeader', null);
        this.setupTrueTonnageFormData();
      },
      toggleAccountingCSV: function toggleAccountingCSV() {
        this.set('pdfOptions.useMinifiedRegionNames', false);
        this.set('ttrCSV', false);
        this.toggleProperty('accountingCSV');
        this.set('pdfOptions.customHeader', null);
        this.toggleProperty('pdfOptions.useMinifiedRegionNames');
        this.setupAccountingTrueTonnageFormData();
      },
      toggleCoLocLobHeaders: function toggleCoLocLobHeaders() {
        this.set('pdfOptions.coLocLob', null);
        this.setDefaultCoLocLobHeaders();
        this.toggleProperty('coLobLobActive');
      }
    }
  });
});